<template>
    <div class="app_container">
        <div class="week_bg_2" style="background-image: url(img/weekParty/top_bg_2.png);">
            <div class="go_back_button" style="background-image: url(img/weekParty/go_back.png);" @click="goBack"></div>
            <!-- <div class="st_share_button" style="background-image: url(img/share_button.png);" @click="goShare"></div> -->
            <div class="main_box">
                <div class="active_title_2" style="background-image: url(img/weekParty/active_rule_title.png);"></div>
                <div class="rule_box" style="background-image: url(img/weekParty/rule_text.png);">
                </div>
                <div class="bottom_bg" style="background-image: url(img/weekParty/bottom_tip.png);"></div>
            </div>

        </div>

    </div>
</template>
  
<script>
import "@/assets/css/weekLuckyMan.css"

export default {
    name: 'activeRule',
    data() {
        return {
        }
    },
    created() {

    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }


    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  